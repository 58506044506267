/* Site header */

.site-header {
    border-bottom: 1px solid var(--border-color);
    // background-color: $lightest-gray;
}

.wrapper-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    padding-top: $spacing-unit / 2;
    padding-bottom: $spacing-unit / 2;
}

.header-left {
    flex: 1 1 auto;

    padding-right: $spacing-unit * 2;

    .site-title {
        @include relative-font-size(1.5);

        &,
        &:visited {
            color: var(--main-text-color);
        }
    }
}

.header-right {
    flex: 0 1 auto;

    .page-link {
        font-size: $large-font-size;

        &:not(:last-child) {
            margin-right: $spacing-unit * 0.75;
        }
    }
}

/* Site footer */

.site-footer {
    border-top: 1px solid var(--border-color);
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
}

.footer-heading {
    @include relative-font-size(1.125);
    margin-bottom: $spacing-unit / 2;
}

.footer-col-wrapper {
    color: $gray;
    text-align: center;

    margin-bottom: $spacing-unit / 4;

    > a {
        white-space: nowrap;
    }
}

/* Images */

.img-wrapper {
    padding-left: $spacing-unit / 2;
    padding-right: $spacing-unit / 2;
}

// Center images
img.center {
    margin: auto; /* Center image horizontally */
    margin-top: $spacing-unit * 0.75;
    margin-bottom: $spacing-unit * 0.125;
    padding: 2px;

    max-width: 100%;
    display: block;
    border: 1px solid $gray;
}

// Add image captions
.empty-caption {
    margin-bottom: $spacing-unit * 0.625;
}

img + em {
    margin-bottom: $spacing-unit * 0.5;
    display: block;
    text-align: center;
    font-size: $small-font-size;
    color: $dark-gray;
}

/* Todo */

.todo {
    border: 2px dashed $red;
    padding: $spacing-unit / 4 $spacing-unit / 2;
    margin: $spacing-unit * 0.75 $spacing-unit / 2;
    overflow: auto;

    &::before {
        content: "TODO:";
        display: block;
        margin-bottom: $spacing-unit / 8;
        font-weight: $bold-font-weight;
    }
}

/* Notes */

.note {
    border: 1px solid $red;
    padding: $spacing-unit / 4 $spacing-unit / 2;
    margin: $spacing-unit * 0.75 $spacing-unit / 2;
    overflow: auto;

    &::before {
        content: "Note:";
        display: block;
        margin-bottom: $spacing-unit / 8;
        font-weight: $bold-font-weight;
    }
}

/* Footnotes */

.footnotes {
}

.footnote {
    &::before {
        content: "[";
    }

    &::after {
        content: "]";
    }
}

.footnotes > ol {
    list-style-type: none;
    counter-reset: ol-counter;

    margin: 0;
    margin-left: $spacing-unit * 0.75;
    margin-right: $spacing-unit * 0.5;
    padding: 0;
    font-size: $small-font-size;

    > li {
        position: relative;
        padding-left: $spacing-unit * 0.75;
    }

    > li::after {
        content: "[" counter(ol-counter) "]:";
        counter-increment: ol-counter;

        position: absolute;
        top: 0;
        left: -$spacing-unit;

        text-align: right;
        width: $spacing-unit * 1.5;
        color: $gray;

        /* For older browsers and Safari */
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

/* Page */

.page-content {
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit / 2;

    flex: 1;
}

.page-title {
}

/* Posts */

.post-header {
    margin-bottom: $spacing-unit / 2;
}

.post-title {
    @include relative-font-size(2);
    line-height: 1;
    margin-bottom: $spacing-unit / 8;
    padding: 0;
    border: 0;

    @include media-query($on-palm) {
        @include relative-font-size(1.75);
    }
}

.post-meta {
    color: $gray;
    font-size: $small-font-size;
}

.post-content {
    text-align: justify;

    @include media-query($on-palm) {
        text-align: left;
    }
}

.post-list {
    margin-left: $spacing-unit / 2;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit / 2;
    }
}

.post-info {
    margin-bottom: $spacing-unit / 2;
}

.post-link {
    font-size: $large-font-size;
}

.post-description {
    font-size: $small-font-size;
}

.related-posts {
    color: $gray;
    font-size: $small-font-size;
}

.related-posts-text {
}

.post-filed {
    color: $gray;
    font-size: $small-font-size;

    display: flex;

    p {
        flex: 1 1 auto;

        &:last-child {
            padding-left: $spacing-unit / 2;
            text-align: right;
        }
    }
}

.post-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: $small-font-size;

    p {
        flex: 1 1 0;
        width: 45%;

        &:first-child {
            padding-right: $spacing-unit / 4;
        }

        &:last-child {
            padding-left: $spacing-unit / 4;
            text-align: right;
        }
    }
}

.post-footer {
    padding-bottom: $spacing-unit / 4;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: $spacing-unit;
}

/* Copyright */

.copyright {
    font-size: $smaller-font-size;
    color: $gray;
    text-align: center;
}

/* Spoilers */

.spoiler {
    cursor: pointer;
    display: inline-block;

    color: $blue;
    margin-bottom: $spacing-unit / 2;

    /* Prevent text selection */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input#spoiler-check {
    display: none; /* hide the checkboxes */
}

/* Show */
input ~ .spoiler {
    &::before {
        content: '\2039  ';
    }

    &::after {
        content: '  \203A';
    }

    ~ ul#markdown-toc {
        display: none;
    }
}

/* Hide */
input:checked ~ .spoiler {
    &::before {
        content: '\203A  ';
    }

    &::after {
        content: '  \2039';
    }

    ~ ul#markdown-toc {
        display: block;
    }
}

input:hover ~ .spoiler {
    // box-shadow: 2px 2px 2px 0px $light-gray;
}

/* TOC */

#markdown-toc {
}

#theme-toggle {
    cursor: pointer;
}
