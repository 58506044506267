/* Reset some basic elements */

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

/* Basic styling */

html, html[data-theme="light"] {
    --link-color: #{$blue};
    --visited-link-color: #{$dark-blue};
    --main-background-color: #{$background-color};
    --main-text-color: #{$text-color};
    --border-color: #{$light-gray};
}

html[data-theme="dark"] {
    --link-color: #{$light-blue};
    --visited-link-color: #{$mild-blue};
    --main-background-color: #{$text-color};
    --main-text-color: #{$mild-gray};
    --border-color: #{$dark-gray};
}

@media (prefers-color-scheme: dark) {
    html, html[data-theme="dark"] {
        --link-color: #{$light-blue};
        --visited-link-color: #{$mild-blue};
        --main-background-color: #{$text-color};
        --main-text-color: #{$mild-gray};
        --border-color: #{$dark-gray};
    }

    html[data-theme="light"] {
        --link-color: #{$blue};
        --visited-link-color: #{$dark-blue};
        --main-background-color: #{$background-color};
        --main-text-color: #{$text-color};
        --border-color: #{$light-gray};
    }
}

body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: var(--main-text-color);
    background-color: var(--main-background-color);

    code {
        font-size: $code-font-size;
        font-family: $code-font-family;
        color: $pink;
    }

    pre > code {
        color: $text-color;
        font-size: 12px;
    }

    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;

    display: flex;
    min-height: 100.1vh;
    flex-direction: column;
}

b, strong {
    font-weight: $bold-font-weight;
}

/* Inputs */

textarea {
    padding-top: 2px;
    padding-bottom: 2px;
}

input, textarea, button {
    font: $base-font-weight #{$small-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;

    padding-left: $spacing-unit / 8;
    padding-right: $spacing-unit / 8;
    border: 1px solid $gray;
    border-radius: 3px;

    outline: none;

    &:hover {
        box-shadow: 2px 2px 2px 0px $light-gray;
    }

    &:focus {
        box-shadow: 2px 2px 2px 0px $light-gray;
        border: 1px solid $text-color;
    }

    &:not(:focus):invalid {
        box-shadow: 0px 0px 1px 1px $red;
    }
}

small {
    font-size: $smaller-font-size;
    color: $gray;
}

*::placeholder {
    opacity: 1;
    color: $gray;
    font-size: $smaller-font-size;
}

/* Set `margin-bottom` to maintain vertical rhythm */

h1, h2, h3, h4, h5, h6,
p, blockquote, hr,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

/* Horizontal rule */

hr {
    color: var(--border-color);
    border-style: solid;
}

/* Images */

img {
    vertical-align: middle;
}

/* Figures */

figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}

/* Lists */

ul,
ol {
    margin-left: $spacing-unit;
}

ul {
    list-style: none;
    // padding: 0px;
}

li {
    > ul,
    > ol {
        margin-bottom: 0;
    }
}

ul li {
    /* handle multiline */
    position: relative;
    overflow: visible;
}

ul li::before {
    position: absolute;
    content: '\203A';
    left: -$spacing-unit / 2;
}

/* Links */

a {
    color: var(--link-color);
    text-decoration: none;

    &:visited {
        color: var(--visited-link-color);
    }

    &:hover {
        text-decoration: underline;
    }
}

/* Headings */

h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
    text-align: left;
    display: flex;

    a {
        color: var(--main-text-color);

        &:visited {
            color: var(--main-text-color);
        }

        &:hover {
            text-decoration: none;
        }
    }
}

h1 {
    @include relative-font-size(2);
    line-height: 1;
    padding-bottom: $spacing-unit / 4;
    border-bottom: 1px solid var(--border-color);

    @include media-query($on-palm) {
        @include relative-font-size(1.75);
    }
}

h2 {
    @include relative-font-size(1.625);
    border-bottom: 1px solid var(--border-color);

    @include media-query($on-palm) {
        @include relative-font-size(1.5);
    }
}

h3 {
    @include relative-font-size(1.375);

    @include media-query($on-palm) {
        @include relative-font-size(1.25);
    }
}

/* Blockquotes */
blockquote {
    border-left: 4px solid var(--border-color);
    color: $gray;
    // font-style: italic;
    margin-left: $spacing-unit / 2;
    margin-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;

    > :last-child {
        margin-bottom: 0;
    }
}

/* Wrappers */

.wrapper-header,
.wrapper-content,
.wrapper-footer {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    margin: 0 auto;

    @include media-query($on-palm) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width: calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

/* Icons */

.icon > svg {
    display: inline-block;
    vertical-align: middle;

    path {
        fill: $gray;
    }
}

table {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-unit / 2;
    border-collapse: collapse;
}

table, th, td {
    border: solid 1px var(--border-color);
}

th, td {
    padding: 6px;
}
