@import "minutia";

/* Images */
.img-wrapper {
  text-align: center;
}

img.center {
  display: inline-block;
  margin-top: 7.5px;
}

.img-wrapper em {
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-size: 14.8px;
  color: #5c5c5c;
}
