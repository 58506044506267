/* ibm-plex-sans-300 - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300.eot'); /* IE9 Compat Modes */
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-300italic - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-300italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-regular - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-500 - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-500.eot'); /* IE9 Compat Modes */
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-500.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/ibm-plex-sans/ibm-plex-sans-v2-latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* hack-regular - latin */
@font-face {
    font-family: 'hackregular';
    src: url('/assets/fonts/hack/Hack-Regular-webfont.eot');
    src: url('/assets/fonts/hack/Hack-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/hack/Hack-Regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/hack/Hack-Regular-webfont.woff') format('woff'),
         url('/assets/fonts/hack/Hack-Regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/hack/Hack-Regular-webfont.svg#hackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
