@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Symbols","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$code-font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !default;
$base-font-size:   16px !default;
$code-font-size:   14px !default;
$small-font-size:  $base-font-size * 0.925 !default;
$smaller-font-size: $base-font-size * 0.85 !default;
$large-font-size:  $base-font-size * 1.0625 !default;
$base-font-weight: 400 !default;
$bold-font-weight: 700 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       rgb(33, 37, 41) !default;
$background-color: #fdfdfd !default;

$gray:             #828282 !default;
$mild-gray:        lighten($gray, 25%) !default;
$light-gray:       lighten($gray, 40%) !default;
$lightest-gray:    #f9f9f9 !default;
$dark-gray:        darken($gray, 15%) !default;

$blue:             rgb(0, 123, 255) !default;
$light-blue:       lighten($blue, 17%) !default;
$mild-blue:        darken($blue, 10%) !default;
$dark-blue:        darken($blue, 20%) !default;
$red:              #d65946 !default;
$pink:             rgb(232, 62, 140) !default;
$lightest-red:     #fffafa !default;
$green:            #6aaf50 !default;
$light-purple:     #eef !default;
$lightest-purple:  #f1f1ff !default;

// Width of the content area
$content-width:    880px !default;

$on-palm:          600px !default;
$on-laptop:        880px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin relative-font-size($ratio) {
    font-size: $base-font-size * $ratio;

    code {
        font-size: $code-font-size * $ratio;
    }
}

// Import partials.
@import
    "minutia/fonts",
    "minutia/base",
    "minutia/layout"
;
